
import { Vue, Component, Watch } from 'vue-property-decorator';
import {FormaPagamentoService} from '@/core/services/financeiro';
import {FormaPagamento} from '@/core/models/financeiro';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { Service } from '@/core/services/Service';

@Component
export default class ListaFormaPagamento extends mixins(Vue, ListPage) {
  public service: Service = new FormaPagamentoService();
  public item: Shared.IEntity = new FormaPagamento();

  loading: boolean = false;
  dialogCadastro: boolean = false; 

  titulo: string = 'Forma de Pagamento';
  subTitulo: string = 'tipos de Forma de Pagamento cadastrados no Sistema';
 
  options: any = {
    itemsPerPage: 15
  };

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Nome', value: 'nome' },
  ];

  @Watch("options", { deep: true })
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search, columns, undefined, '', 'id,nome', '').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
    (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
    .finally(() => (this.loading = false));
  }

  Novo(){
    this.item = new FormaPagamento;
    this.dialogCadastro = true;
  }
  
  mounted(){
   // this.Atualizar();
  }
}
